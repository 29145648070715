import React from 'react';
import twitterLogo from '../Assets/logo-white.png';
import discordLogo from '../Assets/discord-logo-white.png';
import degenLogo from '../Assets/DC_Logo_White.png';
import teleLogo from '../Assets/telegram_logo_white.png';

const Footer = () => {
  return (
    <footer className="footer">
      <p>© 2024 Degen Castle Arcade. All rights reserved.</p>
      <div>
         <a href="https://twitter.com/CastleDegen" target='blank'>
          <img src={twitterLogo} alt="Twitter" className="twitter-icon" />
        </a>
        <a href="https://discord.com/CastleDegen" target='blank'>
          <img src={discordLogo} alt="Discord" className="discord-icon" />
        </a>
        <a href="https://t.me/castlearcade" target='blank'>
          <img src={teleLogo} alt="Telegram" className="tele-icon" />
        </a>
        <a href="https://degencastle.com" target='blank'>
          <img src={degenLogo} alt="Degen Castle Arcade" className="degen-icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;