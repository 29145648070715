import React from 'react';

const MyNFTCard = ({ image, tokenId, contractAddress, name }) => {
    return (
        <div className="mynft-card">
            <img src={image} alt={`${name} NFT`} className="mynft-image" />
            <div className="mynft-details">
                <h3>{name}{" "}{"Amulet"}</h3>
                <p>Token ID: {tokenId}</p>
                <p>
                    Contract:{" "}
                    <a href={`https://bscscan.com/address/${contractAddress}`} target="_blank" rel="noopener noreferrer">
                        {contractAddress}
                    </a>
                </p>
            </div>
        </div>
    );
};

export default MyNFTCard;