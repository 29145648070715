import React from 'react';
import '../CSS/Modal.css';

function TransactionModal({ isOpen, status, txHash, onClose }) {
  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <p>Status: {status}</p>
          {txHash && (
            <p>
              <a href={`https://bscscan.com/tx/${txHash}`} target="_blank" rel="noopener noreferrer">
                View transaction on bscscan
              </a>
            </p>
          )}
        </div>
      </div>
    )
  );
}

export default TransactionModal;