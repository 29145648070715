import {
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react-core";
import { EthersExtension } from "@dynamic-labs/ethers-v5";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";

import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import MainInterface from './Components/MainInterface';
import Footer from './Components/Footer';
import './CSS/App.css';
import Background from './Assets/background.mp4';
import Header from './Components/Header';

// Add a new component for BackgroundVideo
const BackgroundVideo = () => (
  <video autoPlay loop muted className="background-video">
    <source src={Background} type="video/mp4" />
  </video>
);

const App = () => {
  return (
    <DynamicContextProvider
      settings={{
        environmentId: "508640ee-2a18-4cf5-bf1c-4301ae1b166e",
        walletConnectorExtensions: [EthersExtension],
        walletConnectors: [
          EthereumWalletConnectors,
        ],
      }}
    >
      <DynamicWagmiConnector>
        <Router>
        <div className="app">
          <RouteRender />
          <Header />
          <div className="top-container">
            <DynamicWidget />
          </div>
          <MainInterface />
          <Footer />
      </div>
      </Router>
      </DynamicWagmiConnector>
    </DynamicContextProvider>
  );
};

// A component to decide when to render the BackgroundVideo
const RouteRender = () => {
  const location = useLocation();

  // Render the background video only on the home route
  if (location.pathname === '/') {
    return <BackgroundVideo />;
  }

  return null;
};

export default App;
