import React, { useState, useEffect, useCallback } from 'react';
import { useAccount, useContractWrite } from 'wagmi';
import { Contract } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import KeyABI from "../ABI/Keys_ABI.json";
import RedeemABI from "../ABI/Redeem.json";
import TransactionModal from './TransactionModal';
import { CONTRACT_ADDRESS_KEYS, CONTRACT_ADDRESS_REDEEM } from './Constants';
import Balances from './Balances';
import "../CSS/App.css"; 

function toWei(value) {
    if (!value || value.trim() === '') {
        return "0";
    }
    return parseUnits(value, 'ether').toString();
}

function Redeem() {
    const { address } = useAccount();
    const [keysForOrbs, setKeysForOrbs] = useState("0");
    const [orbsFromKeys, setOrbsFromKeys] = useState("0");
    const [keysForBnb, setKeysForBnb] = useState("0");
    const [bnbFromKeys, setBnbFromKeys] = useState("0");
    const [isApprovedForOrbs, setIsApprovedForOrbs] = useState(false);
    const [isApprovedForBNB, setIsApprovedForBNB] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [txHash, setTxHash] = useState(null);
    const [transactionState, setTransactionState] = useState('INITIAL'); // Possible values: INITIAL, WAITING_CONFIRMATION, SUBMITTED, FAILED
    const { primaryWallet } = useDynamicContext();
    const [refreshBalanceKey, setRefreshBalanceKey] = useState(0);

    const fetchAllowance = useCallback(async () => {
    const provider = await primaryWallet.connector.ethers?.getWeb3Provider();

            if (!provider) {
              throw new Error("No provider")
            }
    const contract = new Contract(CONTRACT_ADDRESS_KEYS, KeyABI, provider);
    try {
        const fetchedAllowance = await contract.allowance(address, CONTRACT_ADDRESS_REDEEM);
        console.log("Directly fetched allowance:", fetchedAllowance.toString());
        setIsApprovedForOrbs(Number(fetchedAllowance.toString()) >= Number(toWei(keysForOrbs)));
        setIsApprovedForBNB(Number(fetchedAllowance.toString()) >= Number(toWei(keysForBnb)));
    } catch (error) {
        console.error("Direct fetch error:", error);
    }
}, [address, keysForOrbs, keysForBnb, primaryWallet]);


    useEffect(() => {
        fetchAllowance();
    }, [fetchAllowance]);

    const handleBuyWithKey = async () => {
        setIsModalOpen(true);
        setTransactionState('WAITING_CONFIRMATION');
        try {
            await buyWithKey();
        } catch (error) {
            console.error("Error buying with Keys:", error);
        }
    };

    const handleRedeemForBNB = async () => {
        setIsModalOpen(true);
        setTransactionState('WAITING_CONFIRMATION');
        try {
            await redeemForBNB();
        } catch (error) {
            console.error("Error redeeming for BNB:", error);
        }
    };

    const handleApproveForOrbs = async () => {
    setIsModalOpen(true);
    setTransactionState('WAITING_CONFIRMATION');
    try {
        await approveKeyForOrbs();
    } catch (error) {
        console.error("Error in approval for Orbs:", error);
    }
};

const handleApproveForBNB = async () => {
    setIsModalOpen(true);
    setTransactionState('WAITING_CONFIRMATION');
    try {
        await approveKeyForBNB();
    } catch (error) {
        console.error("Error in approval for BNB:", error);
    }
};

const { 
    write: approveKeyForOrbs, 
    isSuccess: approveSuccessForOrbs,
    isError: approveErrorForOrbs,
    data: approveDataForOrbs
} = useContractWrite({
    abi: KeyABI,
    address: CONTRACT_ADDRESS_KEYS,
    functionName: 'approve',
    args: [CONTRACT_ADDRESS_REDEEM, toWei(keysForOrbs)]
});

const { 
    write: approveKeyForBNB, 
    isSuccess: approveSuccessForBNB,
    isError: approveErrorForBNB,
    data: approveDataForBNB
} = useContractWrite({
    abi: KeyABI,
    address: CONTRACT_ADDRESS_KEYS,
    functionName: 'approve',
    args: [CONTRACT_ADDRESS_REDEEM, toWei(keysForBnb)]
});

function startTransactionConfirmationListener(txHash) {
        const interval = setInterval(async () => {
            try {
                const provider = await primaryWallet.connector.ethers?.getWeb3Provider();
                const receipt = await provider.getTransactionReceipt(txHash);
                console.log('Transaction Receipt:', receipt); // Log the receipt for debugging

                if (receipt) {
                    if (receipt.status === 1 || receipt.confirmations > 0) {
                        clearInterval(interval);
                        setTransactionState('COMPLETED');
                        setRefreshBalanceKey(prevKey => prevKey + 1);
                    } else if (receipt.status === 0) {
                        clearInterval(interval);
                        setTransactionState('FAILED');
                    }
                }
            } catch (error) {
                console.error('Error fetching transaction receipt:', error);
                clearInterval(interval);
                setTransactionState('FAILED');
            }
        }, 3000); // Poll every 3 seconds
    }

    useEffect(() => {
        if (approveSuccessForOrbs && approveDataForOrbs && approveDataForOrbs.hash) {
            setIsApprovedForOrbs(true);
            setTxHash(approveDataForOrbs.hash);
            setTransactionState('SUBMITTED');
            startTransactionConfirmationListener(approveDataForOrbs.hash);
        } else if (approveErrorForOrbs) {
            setTransactionState('FAILED');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveSuccessForOrbs, approveErrorForOrbs, approveDataForOrbs]);

 useEffect(() => {
        if (approveSuccessForBNB && approveDataForBNB && approveDataForBNB.hash) {
            setIsApprovedForBNB(true);
            setTxHash(approveDataForBNB.hash);
            setTransactionState('SUBMITTED');
            startTransactionConfirmationListener(approveDataForBNB.hash);
        } else if (approveErrorForBNB) {
            setTransactionState('FAILED');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveSuccessForBNB, approveErrorForBNB, approveDataForBNB]);


   const { 
    isLoading: buyLoading, 
    isSuccess: buySuccess, 
    data: buyData, 
    write: buyWithKey 
} = useContractWrite({
    abi: RedeemABI,
    address: CONTRACT_ADDRESS_REDEEM,
    functionName: 'buyWithKey',
    args: [toWei(keysForOrbs)]
});

const { 
    isLoading: redeemLoading, 
    isSuccess: redeemSuccess, 
    data: redeemData, 
    write: redeemForBNB 
} = useContractWrite({
    abi: RedeemABI,
    address: CONTRACT_ADDRESS_REDEEM,
    functionName: 'redeem',
    args: [toWei(keysForBnb)]
});

   useEffect(() => {
        if (buySuccess && buyData && buyData.hash) {
            setTxHash(buyData.hash);
            setTransactionState('SUBMITTED');
            startTransactionConfirmationListener(buyData.hash);
        } else if (!buyLoading && !buySuccess) {
            setTransactionState('FAILED');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyLoading, buySuccess, buyData]);

    useEffect(() => {
        if (redeemSuccess && redeemData && redeemData.hash) {
            setTxHash(redeemData.hash);
            setTransactionState('SUBMITTED');
            startTransactionConfirmationListener(redeemData.hash);
        } else if (!redeemLoading && !redeemSuccess) {
            setTransactionState('FAILED');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redeemLoading, redeemSuccess, redeemData]);

    function getTransactionMessage() {
        switch (transactionState) {
            case 'INITIAL':
                return '';
            case 'WAITING_CONFIRMATION':
                return 'Confirm transaction in your wallet';
            case 'SUBMITTED':
                return 'Transaction submitted. Waiting for completion.';
            case 'FAILED':
                return 'Transaction failed. Please try again.';
            case 'COMPLETED':
                return 'Transaction completed successfully.';
            default:
                return '';
        }
    }

    const handleKeysForOrbsChange = (value) => {
    if (/^\d*\.?\d*$/.test(value)) {
        setKeysForOrbs(value);
        setOrbsFromKeys((value / 25).toFixed(0));
    }
};

    const handleOrbsFromKeysChange = (value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setOrbsFromKeys(value);
            setKeysForOrbs((value * 25).toFixed(0));
        }
    };

    const handleKeysForBnbChange = (value) => {
    if (/^\d*\.?\d*$/.test(value)) {
        setKeysForBnb(value);
        setBnbFromKeys((value * 0.00004).toFixed(5));
    }
};

    const handleBnbFromKeysChange = (value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setBnbFromKeys(value);
            setKeysForBnb((value / 0.00004).toFixed(0));
        }
    };

   return (
    <div>
        <div className="redeem-container">
            <div className="redeem-content">
                {/* Summon Orbs Section */}
                <h1 className="redeem-title">Redeem Keys for Orbs</h1>
                <div className="redeem-section">
                    <div className="redeem-inputs">
                        <h2>Quantity of Keys</h2>
                        <input 
                            type="text" 
                            placeholder="Enter Quantity of Keys"
                            value={keysForOrbs}
                            onChange={e => handleKeysForOrbsChange(e.target.value)}
                            className="input-box"
                        />
                        <h2>Quantity of Orbs</h2>
                        <input 
                            type="text" 
                            placeholder="Enter Quantity of Orbs"
                            value={orbsFromKeys}
                            onChange={e => handleOrbsFromKeysChange(e.target.value)}
                            className="input-box"
                        />
                        <p>25 Keys = 1 Orb</p>
                    </div>
                    {isApprovedForOrbs ? (
                        <button onClick={handleBuyWithKey} className="green-button">
                            Summon Orbs
                        </button>
                    ) : (
                        <button onClick={handleApproveForOrbs} className="red-button">
                            Approve Redemption
                        </button>
                    )}
                </div>

                {/* Redeem for BNB Section */}
                <h1 className="redeem-title">Redeem Keys for BNB</h1>
                <div className="redeem-section">
                    <div className="redeem-inputs">
                        <h2>Quantity of Keys</h2>
                        <input 
                            type="text" 
                            placeholder="Enter Quantity of Keys"
                            value={keysForBnb}
                            onChange={e => handleKeysForBnbChange(e.target.value)}
                            className="input-box"
                        />
                        <h2>Amount of BNB</h2>
                        <input 
                            type="text" 
                            placeholder="Enter BNB amount"
                            value={bnbFromKeys}
                            onChange={e => handleBnbFromKeysChange(e.target.value)}
                            className="input-box"
                        />
                        <p>1 Key = 0.00004 BNB</p>
                    </div>
                    {isApprovedForBNB ? (
                        <button onClick={handleRedeemForBNB} className="green-button">
                            Redeem for BNB
                        </button>
                    ) : (
                        <button onClick={handleApproveForBNB} className="red-button">
                            Approve Redemption
                        </button>
                    )}
                </div>
            </div>
        </div>

        <TransactionModal 
            isOpen={isModalOpen} 
            status={getTransactionMessage()} 
            txHash={txHash}
            onClose={() => setIsModalOpen(false)}
        />
        <div>
            <Balances key={refreshBalanceKey} />
        </div>
    </div>
);


}

export default Redeem;