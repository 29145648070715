import React, { useEffect, useState } from 'react';
import { Contract } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { BigNumber } from '@ethersproject/bignumber';
import KeyABI from "../ABI/Keys_ABI.json";
import OrbABI from "../ABI/Orbs_ABI.json";
import CastleABI from "../ABI/Castle_ABI.json";
import { CONTRACT_ADDRESS_ORBS, CONTRACT_ADDRESS_KEYS, CONTRACT_ADDRESS_CASTLE } from './Constants';
import Balances from './Balances';
import MyAmulets from './MyAmulets';
import "../CSS/App.css";

function DegenDashboard() {
    const { primaryWallet } = useDynamicContext();
    
    const [totalOrbsMinted, setTotalOrbsMinted] = useState('0');
    const [totalOrbsBurned, setTotalOrbsBurned] = useState('0');

    const [totalKeysMinted, setTotalKeysMinted] = useState('0');
    const [totalKeysBurned, setTotalKeysBurned] = useState('0');

    const [totalSupplyCastle, setTotalSupplyCastle] = useState('0');
    const [totalCastleBurned, setTotalCastleBurned] = useState('0');

    useEffect(() => {
            const fetchStatistics = async () => {
                if (!primaryWallet) {
                alert("Please connect your wallet");
                return;
            }

            const provider = await primaryWallet.connector.ethers?.getWeb3Provider();

            if (!provider) {
                throw new Error("No provider");
            }
            const keysContract = new Contract(CONTRACT_ADDRESS_KEYS, KeyABI, provider);
            const orbsContract = new Contract(CONTRACT_ADDRESS_ORBS, OrbABI, provider);
            const castleContract = new Contract(CONTRACT_ADDRESS_CASTLE, CastleABI, provider);

            // Orbs Statistics
            const orbsMinted = BigNumber.from(await orbsContract.totalMinted());
            const orbsBurned = BigNumber.from(await orbsContract.totalBurned());

            setTotalOrbsMinted(formatUnits(orbsMinted.toString(), 18).split('.')[0]);
            setTotalOrbsBurned(formatUnits(orbsBurned.toString(), 18).split('.')[0]);

            // Keys Statistics
            const keysMinted = BigNumber.from(await keysContract.totalMinted());
            const keysBurned = BigNumber.from(await keysContract.totalBurned());

            setTotalKeysMinted(formatUnits(keysMinted.toString(), 18).split('.')[0]);
            setTotalKeysBurned(formatUnits(keysBurned.toString(), 18).split('.')[0]);

            // Castle Statistics
            const castleSupply = BigNumber.from(await castleContract.totalSupply());
            const castleBurned = BigNumber.from(await castleContract.totalBurned());

            setTotalSupplyCastle(formatUnits(castleSupply.toString(), 18).split('.')[0]);
            setTotalCastleBurned(formatUnits(castleBurned.toString(), 18).split('.')[0]);
        };

        fetchStatistics();
    }, [primaryWallet]);

    const totalOrbsCirculatingBigNumber = BigNumber.from(totalOrbsMinted).sub(BigNumber.from(totalOrbsBurned));
    const totalKeysCirculatingBigNumber = BigNumber.from(totalKeysMinted).sub(BigNumber.from(totalKeysBurned));
    const keysAvailableBigNumber = totalOrbsCirculatingBigNumber.mul(20);
    const bnbValueOfKeysBigNumber = keysAvailableBigNumber.mul(40000000000000); // This value represents 0.0004 in Wei format
    const formattedBNBValueOfKeys = parseFloat(formatEther(bnbValueOfKeysBigNumber.toString())).toFixed(5);

 

    return (
        <div>
            <h1>Castle Statistics</h1>
        <div className="dashboard-container">
            <div className="dashboard-section-wrapper">
                
                <section className="dashboard-section">
                    <h3>Castle Initial Supply</h3>
                    <div>5,000,000</div>
                    <hr style={{ border: '1px solid #ea580c', margin: '20px 0' }} />
                    <h3>Castle Burned</h3>
                    <div>{totalCastleBurned}</div>
                    <hr style={{ border: '1px solid #ea580c', margin: '20px 0' }} />
                    <h3>Castle Current Supply</h3>
                    <div>{totalSupplyCastle}</div>
                </section>

                <section className="dashboard-section">
                    <h3>Orbs Summoned</h3>
                    <div>{totalOrbsMinted}</div>
                    <hr style={{ border: '1px solid #ea580c', margin: '20px 0' }} />
                    <h3>Orbs Destroyed</h3>
                    <div>{totalOrbsBurned}</div>
                    <hr style={{ border: '1px solid #ea580c', margin: '20px 0' }} />
                    <h3>Orbs Circulating</h3>
                    <div>{totalOrbsCirculatingBigNumber.toString()}</div>
                </section>

                <section className="dashboard-section">
                    <h3>Keys Crafted</h3>
                    <div>{totalKeysMinted}</div>
                    <hr style={{ border: '1px solid #ea580c', margin: '20px 0' }} />
                    <h3>Keys Redeemed</h3>
                    <div>{totalKeysBurned}</div>
                    <hr style={{ border: '1px solid #ea580c', margin: '20px 0' }} />
                    <h3>Keys Circulating</h3>
                    <div>{totalKeysCirculatingBigNumber.toString()}</div>
                </section>
                
                <section className="dashboard-section">
                    <h3>Available Keys</h3>
                    <div>{keysAvailableBigNumber.toString()}</div>
                    <hr style={{ border: '1px solid #ea580c', margin: '20px 0' }} />
                    <h3>BNB Value</h3>
                    <div>{formattedBNBValueOfKeys}</div>
                </section>
            </div>
            <MyAmulets />
            <Balances />
        </div>
        </div>
    );
}

export default DegenDashboard;