import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import EarthABI from '../ABI/Earth_ABI.json';
import FireABI from '../ABI/Fire_ABI.json';
import MagicABI from '../ABI/Magic_ABI.json';
import WaterABI from '../ABI/Water_ABI.json';
import { EarthContractAddress, FireContractAddress, WaterContractAddress, MagicContractAddress } from './Constants';
import MyNFTCard from './MyNFTCard';

import EarthImage from '../Assets/Earth_NFT.gif';
import FireImage from '../Assets/Fire_NFT.gif';
import WaterImage from '../Assets/Water_NFT.gif';
import MagicImage from '../Assets/Magic_NFT.gif';

const MyAmulets = () => {
    const { address } = useAccount();
    const { primaryWallet } = useDynamicContext();
    const [userNFTs, setUserNFTs] = useState({ Earth: [], Fire: [], Water: [], Magic: [] });

    const contracts = [
        { name: 'Earth', address: EarthContractAddress, abi: EarthABI },
        { name: 'Fire', address: FireContractAddress, abi: FireABI },
        { name: 'Water', address: WaterContractAddress, abi: WaterABI },
        { name: 'Magic', address: MagicContractAddress, abi: MagicABI },
    ];

    useEffect(() => {
        if (!address) return;

        const fetchNFTs = async () => {
            const provider = await primaryWallet.connector.ethers?.getWeb3Provider();

            if (!provider) {
              throw new Error("No provider")
            }
            let nfts = { Earth: [], Fire: [], Water: [], Magic: [] };

            for (let contractInfo of contracts) {
                const contract = new ethers.Contract(contractInfo.address, contractInfo.abi, provider);
                const balance = await contract.balanceOf(address);
                for (let i = 0; i < balance; i++) {
                    const tokenId = await contract.tokenOfOwnerByIndex(address, i);
                    nfts[contractInfo.name].push(tokenId.toString());
                }
            }
            setUserNFTs(nfts);
        };

        fetchNFTs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const nftImages = {
        'Earth': EarthImage,
        'Fire': FireImage,
        'Water': WaterImage,
        'Magic': MagicImage,
    };

    // Check if the user has any NFTs
    const hasNFTs = Object.values(userNFTs).some(tokenIds => tokenIds.length > 0);

    return (
        <div>
            <h1>Equipped Amulets</h1>
                <div className="dashboard">
                   {hasNFTs ? (
                        Object.entries(userNFTs).map(([collectionName, tokenIds]) => {
                           if (tokenIds.length > 0) {
                               return (
                                   <MyNFTCard
                                      key={collectionName}
                                      image={nftImages[collectionName]}
                                      tokenId={tokenIds[0]}
                                      contractAddress={contracts.find(c => c.name === collectionName).address}
                                      name={collectionName}
                                    />
                              );
                          }
                          return null;
                        })
                   ) : (
                        <h2>You do not have any Amulets to display</h2>
                 )}
             </div>
        </div>
    );
};

export default MyAmulets;