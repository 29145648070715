import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { Contract } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { CONTRACT_ADDRESS_ORBS, CONTRACT_ADDRESS_KEYS, CONTRACT_ADDRESS_CASTLE } from './Constants';
import KeyABI from "../ABI/Keys_ABI.json";
import OrbABI from "../ABI/Orbs_ABI.json";
import CastleABI from "../ABI/Castle_ABI.json";
import "../CSS/App.css"; 

function Balances() {
    const { address } = useAccount();
    const { primaryWallet } = useDynamicContext();
    const [bnbBalance, setBnbBalance] = useState('0');
    const [castleBalance, setCastleBalance] = useState('0');
    const [orbsBalance, setOrbsBalance] = useState('0');
    const [keysBalance, setKeysBalance] = useState('0');

    useEffect(() => {
    const fetchBalances = async () => {
        if (!address || !primaryWallet) {
            alert("Please connect your wallet");
            return;
        }

        try {
            const provider = await primaryWallet.connector.ethers?.getWeb3Provider();

            if (!provider) {
                throw new Error("No provider");
            }

            const keysContract = new Contract(CONTRACT_ADDRESS_KEYS, KeyABI, provider);
            const orbsContract = new Contract(CONTRACT_ADDRESS_ORBS, OrbABI, provider);
            const castleContract = new Contract(CONTRACT_ADDRESS_CASTLE, CastleABI, provider);

            // Fetching BNB balance and format it
            const bnb = await provider.getBalance(address);
            const bnbFormatted = formatEther(bnb);
            setBnbBalance(Number(bnbFormatted).toFixed(5));  // Updated formatting

            // Fetching BNB balance and format it
            const castle = await castleContract.balanceOf(address);
            const castleFormatted = formatEther(castle);
            setCastleBalance(Number(castleFormatted).toFixed(0));  // Updated formatting

            // Fetching Orbs and Keys balances and format them to show whole numbers
            const orbs = await orbsContract.balanceOf(address);
            setOrbsBalance(formatUnits(orbs.toString(), 18).split('.')[0]);

            const keys = await keysContract.balanceOf(address);
            setKeysBalance(formatUnits(keys.toString(), 18).split('.')[0]);
        } catch (error) {
            console.error("Failed to fetch balances:", error);
            // Additional error handling if necessary
        }
    };

    fetchBalances();
}, [address, primaryWallet]);


    return (
        <div className="balance-container">
            <div className="balance-item">
                <strong>BNB:</strong> {bnbBalance}
            </div>
            <div className="balance-item">
                <strong>Castle:</strong> {castleBalance}
            </div>
            <div className="balance-item">
                <strong>Orbs:</strong> {orbsBalance}
            </div>
            <div className="balance-item">
                <strong>Keys:</strong> {keysBalance}
            </div>
        </div>
    );
}

export default Balances;
