import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import Dashboard from './Dashboard';
import Summon from './Summon';
import Redeem from './Redeem';

function MainInterface() {
    return (
        <div>
            <div className="nav-wrapper">
                <nav className="navigation">
                    <NavLink to="/Dashboard" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>Degen Dashboard</NavLink>
                    <NavLink to="/Summon" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>Orb Summoner</NavLink>
                    <NavLink to="/Redeem" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>The Redeemer</NavLink>
                </nav>
            </div>
            <div className="main-content">
            <Routes>
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/Summon" element={<Summon />} />
                <Route path="/Redeem" element={<Redeem />} />
            </Routes>
            </div>
        </div>
    );
}

export default MainInterface;