import React, { useState, useEffect } from 'react';
import { parseUnits, formatEther } from 'ethers/lib/utils';
import ORB_TOKEN_SALE_ABI from "../ABI/Orbs_Sale_ABI.json";
import { useContractWrite, useAccount } from 'wagmi';
import TransactionModal from './TransactionModal';
import { CONTRACT_ADDRESS_ORBS_SALE } from './Constants';
import Balances from './Balances';
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import "../CSS/App.css"; 

function toWei(etherString) {
  return parseUnits(etherString, 18);
}

function Summon() {
  const { address } = useAccount();
  const [bnbAmount, setBnbAmount] = useState("");
  const [orbAmount, setOrbAmount] = useState("");
  const [userBnbBalance, setUserBnbBalance] = useState("0");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const [transactionState, setTransactionState] = useState('INITIAL'); // Possible values: INITIAL, WAITING_CONFIRMATION, SUBMITTED, FAILED, COMPLETED
  const { primaryWallet } = useDynamicContext();
  const [refreshBalanceKey, setRefreshBalanceKey] = useState(0);

  const { isLoading, isSuccess, data, write } = useContractWrite({
    address: CONTRACT_ADDRESS_ORBS_SALE,
    abi: ORB_TOKEN_SALE_ABI,
    functionName: 'buyWithBNB',
  });

  function purchaseWithBNB(etherAmountAsString) {
    const amountInWei = toWei(etherAmountAsString).toString();
    setIsModalOpen(true);
    setTransactionState('WAITING_CONFIRMATION');
    
    write({ value: amountInWei });
  }

 useEffect(() => {
    if (address) {
        const fetchBalance = async () => {
            const provider = await primaryWallet.connector.ethers?.getWeb3Provider();

            if (!provider) {
              throw new Error("No provider")
            }
            const balance = await provider.getBalance(address);
            setUserBnbBalance(formatEther(balance.toString()));
        };

        fetchBalance();
    }
}, [address, primaryWallet]);

  useEffect(() => {
  function startTransactionConfirmationListener(txHash) {
  const interval = setInterval(async () => {
    try {
      const provider = await primaryWallet.connector.ethers?.getWeb3Provider();
      const receipt = await provider.getTransactionReceipt(txHash);
      console.log('Transaction Receipt:', receipt); // Log the receipt for debugging

      if (receipt) {
        if (receipt.status === 1 || receipt.confirmations > 0) {
          clearInterval(interval);
          setTransactionState('COMPLETED');
          setRefreshBalanceKey(prevKey => prevKey + 1);
        } else if (receipt.status === 0) {
          clearInterval(interval);
          setTransactionState('FAILED');
        }
      }
    } catch (error) {
      console.error('Error fetching transaction receipt:', error);
      clearInterval(interval);
      setTransactionState('FAILED');
    }
  }, 3000); // Poll every 3 seconds
}


  if (isSuccess && data && data.hash) {
    setTxHash(data.hash);
    setTransactionState('SUBMITTED');
    startTransactionConfirmationListener(data.hash);
  } else if (!isLoading && !isSuccess) {
    setTransactionState('FAILED');
  }
}, [isLoading, isSuccess, data, primaryWallet.connector.ethers]);

  const handleBnbChange = (value) => {
    setBnbAmount(value);
    setOrbAmount((value / 0.001).toFixed(0)); // Calculate Orbs from BNB
  };

  const handleOrbChange = (value) => {
    setOrbAmount(value);
    setBnbAmount((value * 0.001).toFixed(3)); // Calculate BNB from Orbs
  };

  const canAfford = parseFloat(bnbAmount) <= parseFloat(userBnbBalance);

  const isValueEntered = bnbAmount !== "" || orbAmount !== "";

function getTransactionMessage() {
    switch (transactionState) {
      case 'INITIAL':
        return '';
      case 'WAITING_CONFIRMATION':
        return 'Confirm transaction in your wallet';
      case 'SUBMITTED':
        return `Transaction submitted. Waiting for completion.`;
      case 'FAILED':
        return 'Transaction failed. Please try again.';
      case 'COMPLETED':
        return 'Transaction completed successfully.';
      default:
        return '';
    }
  }

return (
    <div className="summon">
      <h1>Summon Orbs</h1>
      <section className="orbs-section">
        <div className="orbs-content">
            <h2>Amount of BNB</h2>
            <input 
                type="text" 
                placeholder="Enter BNB amount"
                value={bnbAmount}
                onChange={e => handleBnbChange(e.target.value)}
                className="input-box" 
            />
            <h2>Quantity of Orbs</h2>
            <input 
                type="text" 
                placeholder="Enter Quantity of Orbs"
                value={orbAmount}
                onChange={e => handleOrbChange(e.target.value)}
                className="input-box" 
            />
            <p>1 Orb = 0.001 BNB</p>
            <button 
                onClick={() => purchaseWithBNB(bnbAmount)}
                disabled={!canAfford}
                className="summon-button" 
            >
                Summon Orbs
            </button>
            {!canAfford && isValueEntered && <p className="insufficient-funds">You do not have enough BNB for this transaction.</p>}
        </div>
      </section>
     <TransactionModal 
          isOpen={isModalOpen} 
          status={getTransactionMessage()} 
          txHash={txHash}
          onClose={() => setIsModalOpen(false)}
      /> 
      <div>
          <Balances key={refreshBalanceKey} />
      </div>
    </div>
);

}

export default Summon;